import axios from "axios";
// import { browserName, osName, isMobile } from "react-device-detect";

// const BaseURL = "http://localhost:4000";
const BaseURL = "https://apibasicweb.betonperkasa.com";

const API = axios.create({
  baseURL: BaseURL,
});

export { API, BaseURL };
