const defaultState = {
  id: null,
  fullname: null,
  loading: false,
  error: {},
  isLogin: false,
  dataContact: [],
  totalContact: 0,
  dataLocation: [],
  totalLocation: 0,
  dataBrand: [],
  totalBrand: 0,
  dataCategoryProduct: [],
  totalCategoryProduct: 0,
  dataProduct: [],
  totalProduct: 0,
  dataService: [],
  totalService: 0,
  dataCategoryProject: [],
  totalCategoryProject: 0,
  dataProject: [],
  totalProject: 0,
  dataAboutUs: [],
  totalAboutUs: 0,
  dataEvent: [],
  totalEvent: 0,
  dataNews: [],
  totalNews: 0,
  dataBanner: [],
  totalBanner: 0,
  dataMetaTag: [],
  totalMetaTag: 0
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state, ...action.payload, loading: false,
      }
    }
    case 'FETCH_DATA_SUCCESS': {
      return {
        ...state, ...action.payload, loading: false,
      }
    }
    case 'LOGOUT': {
      return {
        id: null,
        fullname: null,
        loading: false,
        error: {},
        isLogin: false,
        ...state,
      }
    }
    case 'FETCH_DATA_LOADING': {
      return {
        ...state, loading: true
      }
    }
    case 'FETCH_DATA_ERROR': {
      return {
        ...state, error: action.payload, loading: false
      }
    }
    default:
      return state
  }
}

export default reducer